<template>
    <div>
        <Loader v-if="actionsLoading" />

        <st-button
            variant="light"
            class="st-btn-text-danger"
            :callback="reassignStaff"
            v-if="showReassignButton"
        >
            <i class="fa fa-user"></i>
            {{ $t("APPLICATION.VIEW.REASSIGN_STAFF") }}
        </st-button>

        <st-button
            v-if="showAssignButton"
            variant="light"
            class="st-btn-text-danger"
            :callback="reassignStaff"
        >
            <i class="fa fa-user"></i>
            {{ $t("APPLICATION.VIEW.ASSIGN_STAFF") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-success"
            v-if="showApproveReject"
            :callback="approveDocument"
            :spinner="isApproveLoading"
        >
            <i class="fas fa-check"></i>
            {{ $t("APPLICATION.VIEW.APPROVE_DOCUMENT") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-danger"
            v-if="showApproveReject"
            :callback="rejectDocument"
        >
            <i class="fas fa-times"></i>
            {{ $t("APPLICATION.VIEW.REJECT_DOCUMENT") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-primary"
            v-if="showSignButton"
            :callback="signDocument"
            :disabled="signLoading"
            :spinner="signLoading"
        >
            <i class="fa fa-save"></i>
            {{ $t("APPLICATION.VIEW.SIGN_DOCUMENT") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-warning"
            v-if="showClarifications"
            :callback="openClarificationModal"
        >
            <i class="fa fa-question"></i>
            {{ $t("APPLICATION.VIEW.SEND_CLARIFICATIONS") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-primary"
            v-if="showCreateInternalDocument"
            :callback="createInternalDocument"
        >
            <i class="fa fa-file"></i>
            {{ $t("APPLICATION.VIEW.CREATE_INTERNAL_DOCUMENT") }}
        </st-button>

        <st-button
            variant="danger"
            :callback="returnDocument"
            v-if="showReturnButton"
        >
            <i class="fa fa-times"></i>
            {{ $t("APPLICATION.VIEW.RETURN_DOCUMENT") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-success"
            v-if="showCloseButton"
            :callback="closeApplication"
        >
            <i class="fas fa-check"></i>
            {{ $t("APPLICATION.VIEW.CLOSE_APPLICATION") }}
        </st-button>

        <confirm-sign-document-modal
            :applicationId="applicationId"
            ref="confirm-sign-document-modal"
            @signed="applicationSync"/>

        <application-response-notification-modal
            :applicationId="applicationId"
            @rejectApplication="rejectApplication"
            ref="application-response-notification-modal"/>

        <assign-staff-modal
            ref="assignStaffModal"
            :applicationId="applicationId"
            @refreshActions="refreshActions"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmSignDocumentModal from "./ConfirmSignDocumentModal";
import Message from "@/shared/message/message";
import ApplicationResponseNotificationModal from "./ApplicationResponseNotificationModal";
import AssignStaffModal from "./AssignStaffModal";
import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';

export default {
    name: "ApplicationViewToolbar",
    components: {
        ConfirmSignDocumentModal,
        ApplicationResponseNotificationModal,
        AssignStaffModal,
    },
    data() {
        return {
            actionsLoading: false,
            statusProcessingExists: true,
            polling: null,
            userActionsStatus: {
                PROCESSING: 'PROCESSING',
                RESOLVED: 'RESOLVED'
            },
            userActionsTypes: {
                DOCUMENT_GENERATION_REQUEST: 'DOCUMENT_GENERATION_REQUEST',
                SIGN_DOCUMENT: 'SIGN_DOCUMENT',
                APPLICATION_REVIEW: 'APPLICATION_REVIEW'
            },
            canSignStatus: ['pending', 'approved', 'sign_pending', 'rejected', 'rejected_in_progress'],
            isApproveLoading: false,
            requireReviewStatus: ['approved', 'rejected'],
            signLoading: false,
            applicationType: {},
            hideCloseButton: false,
        }
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            userActions: "applications/form/userActions",
            currentUser: "auth/currentUser",
            accountDetails: "auth/accountDetails",
        }),
        hideAssignButton() {
            return this.record?.assigned_staff_user_id;
        },
        showSignButton() {
            //assigned user id from sign action should correspond with current user id or
            //user function should correspond with the sign action function

            if (this.record?.internal_status === 'approved' && this.record?.has_pending_internal_applications) {
                return false;
            }

            if (this.record?.locked) {
                return false;
            }

            const signAction = this.getLastSignAction();

            let isUserAssigned = false;
            let userHasFunction = false;

            if (signAction && signAction.status === this.userActionsStatus.PROCESSING) {
                if (signAction?.user_id) {
                    isUserAssigned = signAction.user_id === this.currentUser.user_id;
                }

                if (signAction?.function_type) {
                    userHasFunction = !!this.accountDetails?.functions.includes(signAction.function_type);
                }
            }
            return (this.canSignStatus.includes(this.record?.internal_status)) && (isUserAssigned || userHasFunction);
        },
        showApproveReject() {
            return this.hideAssignButton
                && (this.record.internal_status === "pending" || this.record.internal_status === "review")
                && this.record?.assigned_staff_user_id === this.currentUser.user_id
                && this.reviewDocumentAction?.length
        },
        showClarifications() {
            return this.hideAssignButton
                && (this.record.internal_status === "pending")
                && this.record?.assigned_staff_user_id === this.currentUser.user_id
                && !this.signDocumentActions?.length
                && this.reviewDocumentAction
        },
        showCreateInternalDocument() {
            if (this.applicationType && !this.applicationType.internal_application_type_id) {
                return false;
            }
            if (this.record?.has_pending_internal_applications) {
                return false;
            }
            return this.hideAssignButton
                && (this.record.internal_status === "approved")
                && this.record?.assigned_staff_user_id === this.currentUser.user_id;
        },
        applicationId() {
            return this.record?.id || '';
        },
        signDocumentActions() {
            return this.userActions.filter((el) => el.action_type === this.userActionsTypes.SIGN_DOCUMENT);
        },
        generateDocumentActions() {
            return this.userActions.filter((el) => el.action_type === this.userActionsTypes.DOCUMENT_GENERATION_REQUEST);
        },
        reviewDocumentAction(){
            return this.userActions.filter((el) => el.action_type === this.userActionsTypes.APPLICATION_REVIEW && el.status === this.userActionsStatus.PROCESSING);
        },
        showReturnButton() {
            const currentProcessingAction = this.userActions.find((el) => el.status === this.userActionsStatus.PROCESSING);
            if (currentProcessingAction?.can_return) {
                if (currentProcessingAction.action_type === this.userActionsTypes.SIGN_DOCUMENT) {
                    return this.showSignButton;
                } else {
                    return currentProcessingAction.can_return;
                }
            }

            return false;
        },
        hasPermissionToReassignStaff() {
            return new ApplicationsPermissions(this.currentUser).reassignApplication;
        },
        showAssignButton() {
            return this.record?.identification_number && !this.record?.assigned_staff_user_id && !this.isDocumentGenerated;
        },
        isDocumentGenerated() {
            return !!this.generateDocumentActions.some((el) => el.status === this.userActionsStatus.RESOLVED);
        },
        showReassignButton() {
            return this.hasPermissionToReassignStaff && this.record?.assigned_staff_user_id && !this.isDocumentGenerated;
        },
        canRejectApplication() {
            return !!(this.applicationType?.reject_form_staff_id && this.applicationType?.reject_document_template_id);
        },
        showCloseButton() {
            const humanReviewAction = this.userActions.find((el) => el.status === this.userActionsStatus.PROCESSING && el.action_type === 'HUMAN_REVIEW');

            return !!humanReviewAction;
        }
    },
    methods: {
        ...mapActions({
            assign: "applications/form/assignStaff",
            approveDoc: "applications/form/approveDocument",
            rejectDoc: "applications/form/rejectDocument",
            signDoc: "applications/form/signDocument",
            doFindUserActions: "applications/form/findUserActions",
            initApplicationState: "applications/form/initFormState",
            doFind: "applications/form/find",
            resetUserActions: "applications/form/doResetUserActions",
            addComment: "applications/form/addComments",
            doFindApplicationType: "applications/form/findApplicationType",
            doSendClarifications: 'applications/form/sendClarifications',
            doCloseApplication: "applications/form/closeApplication"
        }),
        reassignStaff() {
            this.$refs.assignStaffModal.show();
        },
        returnDocument() {
            this.$alert({
                type: "warning",
                text: this.$t("APPLICATION.VIEW.RETURN_DOCUMENT_MESSAGE"),
                confirmButtonText: this.$t("GENERAL.YES"),
                cancelButtonText: this.$t("GENERAL.NO"),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.$refs.assignStaffModal.show(true);
                },
            });

        },
        approveDocument() {
            this.$alert({
                type: "warning",
                text: this.$t("APPLICATION.VIEW.APPROVE_DOCUMENT_MESSAGE"),
                confirmButtonText: this.$t("GENERAL.YES"),
                cancelButtonText: this.$t("GENERAL.NO"),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.approveApplication();
                },
            });
        },
        rejectDocument() {
            if (this.canRejectApplication) {
                this.$alert({
                    type: "warning",
                    text: this.$t("APPLICATION.VIEW.REJECT_DOCUMENT_MESSAGE"),
                    confirmButtonText: this.$t("GENERAL.YES"),
                    cancelButtonText: this.$t("GENERAL.NO"),
                    hasConfirmation: true,
                    confirmCallback: () => {
                        this.$refs['application-response-notification-modal'].show();
                    },
                });
            } else {
                return Message.error('ERRORS.REJECT_FORM_STAFF');
            }
        },
        signDocument() {
            const action = this.signDocumentActions.map((el) => el.action_type);
            const lastOccurence = action.lastIndexOf('SIGN_DOCUMENT');

            if (lastOccurence !== -1) {
                this.signLoading = true;

                this.signDoc({ id: this.$route.params.id, action: this.signDocumentActions[lastOccurence].id}).then(() => {
                    this.$refs['confirm-sign-document-modal'].show();
                    this.signLoading = false;
                }).catch(() => {
                    this.signLoading = false;
                    Message.error('APPLICATION.VIEW.SIGN_DOCUMENT_ERROR');
                });
            } else {
                Message.error('APPLICATION.VIEW.SIGN_DOCUMENT_ERROR');
            }
        },
        async processUserActions() {
            if (this.$route.params?.id) {
                try {
                    this.actionsLoading = true;
                    await this.getUserActions();
                    if (this.statusProcessingExists.length !== 0) {
                        this.actionsLoading = false;
                        clearInterval(this.polling);

                        //get updated application data
                        await this.doFind({id: this.$route.params.id, loadStaff: true});
                    }
                } catch (error) {
                    clearInterval(this.polling);
                    Message.error(error);
                }
            }
        },
        async syncUserActions() {
            try {
                this.actionsLoading = true;

                await this.getUserActions(true);
                if (this.isApplicationSignedByCurrentUser()) {
                    this.actionsLoading = false;
                    await this.doFind({id: this.$route.params.id, loadStaff: true});
                    clearInterval(this.polling);
                }
            } catch (error) {
                clearInterval(this.polling);
            }
        },
        async getUserActions(isSync = false) {
            try {
                const paramsResolved = {
                    query: `application_id eq ${this.record.id} and status eq RESOLVED`,
                    sort: '-updated_date'
                };
                this.resolvedUserActions = await this.doFindUserActions(paramsResolved);

               if (!isSync) {
                    const paramsProcessing = {
                        query: `application_id eq ${this.record.id} and status eq PROCESSING`,
                        sort: '-created_date'
                    };
                    this.statusProcessingExists = await this.doFindUserActions(paramsProcessing);
                }
            } catch(error) {
                if (this.polling) {
                    clearInterval(this.polling);
                }
            }
        },
        async approveApplication() {
            //send notification to user
            this.isApproveLoading = true;

            await this.approveDoc(this.$route.params.id);
            await this.doFind({id: this.$route.params.id, loadStaff: true});

            this.isApproveLoading = false;

            this.polling = setInterval(async () => {
                await this.processUserActions();
            }, 2000);

        },
        async rejectApplication(data) {
            await this.rejectDoc(this.$route.params.id);

             //add comments to application
            if (data.comments) {
                await this.addComment({
                    applicationId: this.applicationId,
                    payload: {
                        comment: data.comments,
                        visibility: 'private'
                    }
                });
            }

            await this.doFind({id: this.$route.params.id, loadStaff: true});

            this.polling = setInterval(async () => {
                await this.processUserActions();
            }, 2000);

        },
        async openClarificationModal() {
            if (!this.applicationType?.review_application_type_id) {
                Message.error('APPLICATION.NOTIFIERS.REVIEW_APPLICATION_TYPE');
                return;
            }

            await this.doSendClarifications(this.record.id);

            this.$router.push({
                name: 'internalDocumentNew',
                query: {
                    appId: this.record.id,
                    appTypeId: this.applicationType.review_application_type_id,
                }
            });
        },
        createInternalDocument() {
            this.$router.push({
                name: 'internalDocumentNew',
                query: {
                    appId: this.record.id,
                    appTypeId: this.applicationType.internal_application_type_id,
                }
            });
        },
        applicationSync() {
            if (!this.polling) {
                this.resetUserActions();
                this.polling = setInterval(async () => {
                    await this.syncUserActions();
                }, 2000);
            }
        },
        isApplicationSignedByCurrentUser() {
            if (!this.resolvedUserActions.length) return false;

            const resolvedSignActions = this.resolvedUserActions.filter((el) => el.action_type === 'SIGN_DOCUMENT');
            let isUserAssigned = false;
            let userHasFunction = false;

            resolvedSignActions.forEach((signAction) => {
                if (signAction && signAction.status === this.userActionsStatus.RESOLVED) {
                    if (signAction?.user_id) {
                        isUserAssigned = signAction.user_id === this.currentUser.user_id;
                    }

                    if (signAction?.function_type) {
                        userHasFunction = !!this.accountDetails?.functions.includes(signAction.function_type);
                    }
                }
            });

            return isUserAssigned || userHasFunction;
        },
        getLastSignAction() {
            const actions = this.signDocumentActions.map((el) => el.action_type);
            const lastOccurence = actions.lastIndexOf('SIGN_DOCUMENT');
            const signAction = (lastOccurence !== -1) ? this.signDocumentActions[lastOccurence] : [];

            return signAction;
        },
        async refreshActions() {
            this.resetUserActions();

            this.polling = setInterval(async () => {
                await this.processUserActions();
            }, 2000);
        },
        async closeApplication() {
            await this.doCloseApplication(this.record.id);

            //get updated application data
            setTimeout(async () => {
               await this.doFind({id: this.$route.params.id, loadStaff: true});
            }, 2000)
        }
    },
    async created() {
        //find user actions related to application
        await this.getUserActions();

        this.applicationType = await this.doFindApplicationType(this.record.application_type_id);
    },
    beforeDestroy () {
        // Keep the following line commented (GHIS-2933)
        // this.initApplicationState();
        if (this.polling) {
            clearInterval(this.polling);
        }
    }
};
</script>
